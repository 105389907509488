<template>
  <div>
    <div class="items">
      <h5>{{ $t('pur.供应商名称') }}</h5>
      <p>{{ supplierHeader.companyUsName }}</p>
    </div>
    <div class="items">
      <h5>{{ $t('pur.信用等级') }}</h5>
      <p>{{ supplierHeader.supplierLevel }}</p>
    </div>
    <div class="items">
      <h5>{{ $t('pur.信用额度') }}</h5>
      <p>{{ supplierCredit.creditLimit }}</p>
    </div>
    <div class="items">
      <h5>{{ $t('pur.已用额度') }}</h5>
      <p>{{ supplierCredit.creditLimitUsed }}</p>
    </div>
    <div class="items">
      <h5>{{ $t('pur.超期应收') }}</h5>
      <p>{{ supplierCredit.overdueReceivables }}</p>
    </div>
    <div class="items">
      <h5>{{ $t('pur.可用额度') }}</h5>
      <p>{{ supplierCredit.creditLimitUse }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    supplierId: { type: String, default: '' },
    orgId: { type: String, default: JSON.parse(localStorage.getItem('userInfo'))['companyId'] }
  },
  data() {
    return {
      supplierHeader: {}
    };
  },
  computed: {
    supplierCredit() {
      if (!this.supplierHeader || !this.supplierHeader.supplierOrgCreditList) {
        return {};
      }
      return this.supplierHeader.supplierOrgCreditList.find(item => item.orgId === this.orgId) || {};
    }
  },
  mounted() {
    this.getSupplierCredit();
  },
  methods: {
    getSupplierCredit(id = this.supplierId, orgId = this.orgId) {
      const data = { entity: { id: id, currentOrgId: orgId } };
      this.$myHttp.post('/microarch/masterdata/supplierRegistration/view2', data).then(response => {
        this.supplierHeader = response.data.entity;
      });
    }
  }
};
</script>
